<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-12 col-md-12 p-0">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <h1>Navigate IT Challenges with HashTekFusion's Innovations</h1>
                            <p>To be the foremost leader in IT consulting, renowned for our commitment to surpassing
                                client expectations through groundbreaking innovation and unwavering precision in
                                delivery.
                                We are specialized in Consulting, AWS Cloud Migration,Serverless Application Model,
                                Enterprise Integration and Data Analysis & Reporting</p>
                            <div class="btn-box">
                                <a routerLink="/contact" class="btn btn-primary">Get Started</a>
                                <!--a routerLink="/contact" class="btn btn-light">Get Started</a-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--div class="col-lg-5 col-md-12 p-0">
                <div class="main-banner-image">
                    <img src="assets/img/banner-img1.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div-->
        </div>

        <div class="banner-inner-content">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="single-inner-box">
                        <div class="icon">
                            <i class="flaticon-branch"></i>
                        </div>
                        <!--h3><a routerLink="/service-details">IT Management <i class="flaticon-add-1"></i></a></h3-->
                        <h3>IT Consulting Services <i class="flaticon-add-1"></i></h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="single-inner-box">
                        <div class="icon">
                            <i class="flaticon-paper-plane"></i>
                        </div>
                        <h3>Cloud Services <i class="flaticon-add-1"></i></h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 offset-lg-0 offset-md-0 offset-sm-3">
                    <div class="single-inner-box">
                        <div class="icon">
                            <i class="flaticon-application-1"></i>
                        </div>
                        <h3>Enterprise Integration<i class="flaticon-add-1"></i></h3>
                    </div>
                </div>
            </div>

            <div class="map"><img src="assets/img/circle-map2.png" alt="image"></div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape12.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Services Area -->
<section class="services-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Our Services</span>
                <h2>We Offer Professional IT Solutions & Consulting For Business</h2>
                <p>
                    At HashTekFusion, we specialize in empowering businesses to achieve superior results through
                    strategic software solutions. With over two decades of expertise in Information Technology, we have
                    successfully delivered transformative projects across diverse sectors including Telecom, Banking &
                    Finance, Retail, and e-Commerce.
                    Our core strengths lie in developing cross-platform mobile applications and Progressive Web
                    Applications (PWAs) using our robust frameworks and reusable components. We are committed to
                    integrating cutting-edge technologies to enhance efficiency and scalability.
                    HashTekFusion also excels in assessing and optimizing your current technology stack, offering
                    tailored strategies for seamless migration to cloud-native platforms. Our goal is to ensure your
                    business remains agile and competitive in today's dynamic market landscape.
                </p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title1.png" alt="image">
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-plugin"></i>
                    </div>
                    <h3><a routerLink="/architecture-details">Enterprise & Solution Architecture</a></h3>
                    <p>HashTekFusion developed core architectural artifacts for various architecture domains such as
                        Business Architecture, Application Architecture,...</p>
                    <a routerLink="/architecture-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-application"></i>
                    </div>
                    <h3><a routerLink="/integration-details">Enterprise Integration Services</a></h3>
                    <p>In any big Enterprise to share the data across the verticals/domains will be a big challenge.
                        .Enterprise integration models have evolved over the years...</p>
                    <a routerLink="/integration-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-paper-plane"></i>
                    </div>
                    <h3><a routerLink="/cloud-details">Cloud Solutions</a></h3>
                    <p>HaskTek Professionals specialized in AWS Cloud Technology stack to help our customers to migrate
                        from on premise to cloud platform..</p>
                    <a routerLink="/cloud-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-desktop-application"></i>
                    </div>
                    <h3><a routerLink="/webapp-details">Desktop & Mobile Applications</a></h3>
                    <p>Our developers are able to build native & hybrid mobile applications by leveraging frameworks
                        such as Ionic, NativeScript..</p>
                    <a routerLink="/webapp-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-web"></i>
                    </div>
                    <h3><a routerLink="/portal-details">Content Management and Portal Solutions</a></h3>
                    <p>We are supporting Content Management Solutions such as TeamSite, Adobe Experience Manager ...</p>
                    <a routerLink="/portal-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3><a routerLink="/analytics-details">Data Analytics & Reporting</a></h3>
                    <p>HashTekFusion Data Analysts are expertise in handling enterprise volume data and deliver valuable
                        metrics to business at any scale..</p>
                    <a routerLink="/analytics-details" class="read-more-btn">Read More <i
                            class="flaticon-add-1"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Services Area -->

<!-- Start About Area -->
<section class="about-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>We've Been Thriving in 20 Years The Tech Area</h2>
                    <p> We help you buy, build and integrate software that delivers better results for your Business.
                        Having more than 20+ years of experience in Information Technology and delivering solutions
                        to various high valued customers in Telecom, Banking & Finance, Retail and e-Commerce domains.
                        We specialized in developing cross platform mobile applications and Progressive
                        Web Applications by leveraging our reusable frameworks and components available in sand box.
                        HashTekFusion helps you to analyze your existing technology stack and suggest best approaches to
                        migrate
                        to cloud native platform.</p>
                    <a routerLink="/about" class="btn btn-primary">View More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Why choose us</span>
                <h2>We Different From Others Should Choose Us</h2>
                <p>HashTekFusion experts, helps to transform your business IT platform more agile by adapting industry
                    best pratices and standards and will reduce your capital expenditures. By leveraging architecture
                    frameworks
                    such as TOGAF, Zachman and build a reusable architeture artifacts to achieve target architecture in
                    a short span of time. </p>
            </div>

            <!--div class="image">
                <img src="assets/img/section-title/section-title2.png" alt="image">
            </div-->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-choose-box">
                    <img src="assets/img/why-choose-us/bdd_image.png" alt="image">

                    <!--div class="title">
                        <h3>Behavioral Driven Development</h3>
                    </div>

                    <div class="content">
                        <div class="inner-content">
                            <h3><a routerLink="/service-details">Behavioral Driven Development</a></h3>
                            <p>Always align the implementation to specific features and live documentation</p>
                            <a routerLink="/service-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                        </div>
                    </div-->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-choose-box">
                    <img src="assets/img/why-choose-us/reusable_artifacts.png" alt="reusable_artifacts">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-choose-box">
                    <img src="assets/img/why-choose-us/cloud-native-process-flows.png" alt="cloud_native">
                </div>
            </div>
        </div>
    </div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="shape13"></div>
    <div class="shape15"><img src="assets/img/shape/shape13.svg" alt="shape14"></div>
</section>
<!-- End Why Choose Us Area -->

<!-- Start FunFacts Area -->
<section class="funfacts-area ptb-110 bg-f2feee">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Facts</span>
                <h2>20+ years of industry experience with Fortune 500 clients</h2>
                <p>HashTekFusion Professionals accomplished projects successfully for various Fortune 500 clients in the
                    past 20+ years.
                    Our happy clients includes AT&T, Hewlett Packard, NYSE, GE, Citibank and so on </p>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="80"></div>
                    <span>Entperise Consulting</span>
                    <h3>Solutions</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="90"></div>
                    <span>Our happy</span>
                    <h3>Clients</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="85"></div>
                    <span>Projects</span>
                    <h3>Design</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4 offset-lg-0 offset-md-0 offset-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="90"></div>
                    <span>Completed</span>
                    <h3>Projects</h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FunFacts Area -->
