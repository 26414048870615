import { Injectable } from '@angular/core';
import { Contact } from '../dto/contact';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {

  constructor(private http: HttpClient) { }

  saveProspectEnquiry(enquiry: Contact){
    console.warn(enquiry);
    return this.http.post(environment.enquiryUrl, enquiry)
            .pipe(catchError((err)=>{
                console.log('error caught in service')
                console.error(err);
                return throwError(err);
            }))
  }
}
