import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/dto/contact';
import { ProspectService } from 'src/app/services/prospect.service';
import { AbstractControl,FormBuilder, FormGroup, Validators} from '@angular/forms'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  form: FormGroup;
  submitted =false;
  apiResponse=false;
  apiErrorMsg=null;

  constructor(private prospectService: ProspectService, private formBuilder: FormBuilder) { }

  ngOnInit(): void{
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(75)]],
      email: ['',[Validators.required, Validators.email,Validators.maxLength(75)]],
      subject: ['', [Validators.required, Validators.maxLength(50)]],
      phone:['', [Validators.maxLength(15)]],
      message:['', [Validators.required, Validators.maxLength(250)]]
    })
  }

  submitEnquiry(contactForm: Contact): void{
    this.prospectService.saveProspectEnquiry(contactForm).subscribe((result)=>{
      console.log(result);
    })    
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  submitContact(): void{
      this.submitted = true;
      if (this.form.invalid) {
        return;
      }else{
        this.prospectService.saveProspectEnquiry(this.form.value).subscribe((result)=>{
          console.log(result);
          this.submitted = false;
          this.apiResponse = true;
          this.form.reset();
        },(error)=>{
          this.apiErrorMsg= error;
        })            
      }      
  }

}
