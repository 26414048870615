import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/layout/preloader/preloader.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {FooterComponent} from './components/layout/footer/footer.component';
import {HomeComponent} from './components/pages/home/home.component';
import {AboutComponent} from './components/pages/about/about.component';
import {TeamComponent} from './components/pages/team/team.component';
import {ServicesComponent} from './components/pages/services/services.component';
import {ServiceDetailsComponent} from './components/pages/service-details/service-details.component';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {ArchitectureComponent} from "./components/pages/architecture/architecture.component";
import {IntegrationComponent} from "./components/pages/integration/integration.component";
import {CloudComponent} from "./components/pages/cloud/cloud.component";
import {WebappComponent} from "./components/pages/webapp/webapp.component";
import {PortalComponent} from "./components/pages/portal/portal.component";
import {AnalyticsComponent} from "./components/pages/analytics/analytics.component";

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        AboutComponent,
        TeamComponent,
        ServicesComponent,
        ServiceDetailsComponent,
        NotFoundComponent,
        ContactComponent,
        ArchitectureComponent,
        IntegrationComponent,
        CloudComponent,
        WebappComponent,
        PortalComponent,
        AnalyticsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
