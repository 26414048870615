<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Services</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Our Services</span>
                <h2>We Offer Professional IT Solutions & Consulting For Business</h2>
                <p>In HashTek, we are primarily focusing on SIX core areas to support our clients. Our diversified
                    experience in different domains help our customers to build the features from
                    ideating phase until deployment and support in each and every milestone agreed.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title1.png" alt="image">
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-plugin"></i>
                    </div>
                    <h3><a routerLink="/architecture-details">Enterprise & Solution Architecture</a></h3>
                    <p>HashTek developed core architectural artifacts for various architecture domains such as Business
                        Architecture, Application Architecture,...</p>
                    <a routerLink="/architecture-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-application"></i>
                    </div>
                    <h3><a routerLink="/integration-details">Enterprise Integration Services</a></h3>
                    <p>In any big Enterprise to share the data across the verticals/domains will be a big challenge.
                        .Enterprise integration models have evolved over the years...</p>
                    <a routerLink="/integration-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-paper-plane"></i>
                    </div>
                    <h3><a routerLink="/cloud-details">Cloud Solutions</a></h3>
                    <p>HaskTek Professionals specialized in AWS Cloud Technology stack to help our customers to migrate
                        from on premise to cloud platform..</p>
                    <a routerLink="/cloud-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-desktop-application"></i>
                    </div>
                    <h3><a routerLink="/webapp-details">Desktop & Mobile Applications</a></h3>
                    <p>Our developers are able to build native & hybrid mobile applications by leveraging frameworks
                        such as Ionic, NativeScript..</p>
                    <a routerLink="/webapp-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-web"></i>
                    </div>
                    <h3><a routerLink="/portal-details">Content Management and Portal Solutions</a></h3>
                    <p>We are supporting Content Management Solutions such as TeamSite, Adobe Experience Manager ...</p>
                    <a routerLink="/portal-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3><a routerLink="/analytics-details">Data Analytics & Reporting</a></h3>
                    <p>HashTek Data Analysts are expertise in handling enterprise volume data and deliver valuable
                        metrics to business at any scale..</p>
                    <a routerLink="/analytics-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Services Area -->