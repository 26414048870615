<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Enterprise Integration Services</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Enterprise Integration Services</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-110">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Tailored Integration Patterns</h3>
                <p>Our integration patterns are meticulously documented, making them easy to implement across various
                    domains. Key features include:
                </p>

                <ul class="ul-content">
                    <li><b>Real-Time API Calls:</b> Leveraging protocols such as REST, GraphQL, and gRPC for
                        instantaneous data
                        exchange.
                    </li>
                    <li><b>Event-Driven Approaches:</b> Utilizing powerful tools like Apache Kafka, AWS EventBridge, and
                        AMQP
                        to handle asynchronous data streams.
                    </li>
                    <li><b>Data Replication and Streaming:</b> Ensuring consistent and reliable data flow across your
                        enterprise.
                    </li>
                    <li><b>Batch Processing:</b> Efficiently managing large volumes of data with our optimized batch
                        processing
                        techniques.
                    </li>
                </ul>

                <h3>Why Choose Our Enterprise Integration Services?</h3>
                <ul class="ul-content">
                    <li><b>Customized Solutions:</b> Integration patterns tailored to your specific business needs.</li>
                    <li><b>Comprehensive Documentation:</b> Easy-to-follow guides for quick implementation.</li>
                    <li><b>Advanced Protocols and Tools:</b> Utilizing the latest technologies for optimal performance.
                    </li>
                    <li><b>Cross-Domain Applicability:</b> Versatile solutions that can be adapted to various
                        industries.
                    </li>
                </ul>
                <p>Harness the power of our Enterprise Integration services to streamline your operations and ensure
                    your systems work together seamlessly. At HashTekFusion, we're dedicated to driving your business
                    forward with innovative and efficient integration solutions. </p>
            </div>

            <div class="services-details-image wow fadeInUp">
                <img src="assets/img/services-details/integration.png" alt="integration">
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->