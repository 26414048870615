import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/pages/home/home.component';
import {AboutComponent} from './components/pages/about/about.component';
import {TeamComponent} from './components/pages/team/team.component';
import {ServicesComponent} from './components/pages/services/services.component';
import {ServiceDetailsComponent} from './components/pages/service-details/service-details.component';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {ArchitectureComponent} from "./components/pages/architecture/architecture.component";
import {IntegrationComponent} from "./components/pages/integration/integration.component";
import {CloudComponent} from "./components/pages/cloud/cloud.component";
import {WebappComponent} from "./components/pages/webapp/webapp.component";
import {PortalComponent} from "./components/pages/portal/portal.component";
import {AnalyticsComponent} from "./components/pages/analytics/analytics.component";


const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'team', component: TeamComponent},
    {path: 'services', component: ServicesComponent},
    {path: 'service-details', component: ServiceDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'architecture-details', component: ArchitectureComponent},
    {path: 'integration-details', component: IntegrationComponent},
    {path: 'cloud-details', component: CloudComponent},
    {path: 'webapp-details', component: WebappComponent},
    {path: 'portal-details', component: PortalComponent},
    {path: 'analytics-details', component: AnalyticsComponent},
    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
