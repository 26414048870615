<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Send Message</span>
                <h2>Drop us message for any query</h2>
                <p> Tell us briefly about your requirement and what services are you interested in, who you are and how we can reach you .
                    One of our specialist will reach you soon to deep dive into your requirements.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <span>Address</span>
                            25 Coho Drive, Whitby, ON L1P 0K4, Canada
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <span>Email</span>
                            <a href="mailto:rajesh@hashtekfusion.com">sales&#64;hashtekfusion.com</a>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            <span>Phone</span>
                            <a href="tel:+16472963870">+1 (647) 296-3870</a>                            
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2876.041503372582!2d-78.97067028426498!3d43.87569167911437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d51e31f970d3ed%3A0xc7fa5594f0e10ba1!2s25%20Coho%20Dr%2C%20Whitby%2C%20ON%20L1P%200K6%2C%20Canada!5e0!3m2!1sen!2sus!4v1629822147538!5m2!1sen!2sus" width="800" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe> 
            </div>
            
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="form" (ngSubmit)="submitContact()">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form mb-3">
                                    <input type="text" formControlName="name" id="name" class="form-control" placeholder="Name">
                                </div>
                                <div *ngIf="submitted && f.name.errors" class="error-message">
                                    <div *ngIf="f.name.errors.required">Name is required</div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form mb-3">
                                    <input type="email" formControlName="email" id="email" class="form-control" placeholder="Email">
                                </div>
                                <div *ngIf="submitted && f.email.errors" class="error-message">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.email">Invalid e-mail format</div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form mb-3">
                                    <input type="text" formControlName="phone" id="phone" class="form-control" placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form mb-3">
                                    <input type="text" formControlName="subject" id="subject" class="form-control" placeholder="Subject">
                                </div>
                                <div *ngIf="submitted && f.subject.errors" class="error-message">
                                    <div *ngIf="f.subject.errors.required">Subject is required</div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form mb-3">
                                    <textarea formControlName="message"  class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                                </div>
                                <div *ngIf="submitted && f.message.errors" class="error-message">
                                    <div *ngIf="f.message.errors.required">Message is required</div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div *ngIf="submitted && apiErrorMsg" class="error-message">
                                    <div>There was an issue and please try again later</div>
                                </div>
                                <div *ngIf="apiResponse" class="sent-message">
                                    <div>Your message has been sent. Our representative will reach out shortly. Thank you!</div>
                                </div>
                                <button type="submit" class="btn btn-primary">Send Message</button>                               
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>
<!-- End Contact Area -->