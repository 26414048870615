<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Enterprise & Solutions Architecture</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Enterprise & Solutions Architecture</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-110">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Reusable Architecture Artifacts</h3>
                <p>At HashTekFusion, we specialize in developing robust architectural artifacts that span across various
                    domains, including Business Architecture, Information Systems Architecture, and Technology
                    Architecture. Our team of seasoned architects has meticulously crafted these reusable frameworks for
                    diverse industries such as Financial Services, Telecom, and Healthcare.
                </p>
                <h3>Accelerate Your Project Delivery</h3>
                <p>Our architectural artifacts are designed to streamline your solution development process,
                    significantly reducing design iterations and increasing your development team's productivity. By
                    utilizing our pre-built architectures, your team can focus on delivering core business
                    functionalities without the burden of underlying standard design patterns. </p>
                <h3>Expertise and Industry Standards</h3>
                <p>Our experts leverage industry-standard frameworks like TOGAF and Zachman to ensure our artifacts meet
                    the highest quality benchmarks. Equipped with advanced tools such as Enterprise Architect,
                    LucidChart, and LeanIX, our team has created comprehensive and reusable solutions tailored to your
                    business needs.</p>
            </div>

            <div class="services-details-image wow fadeInUp">
                <img src="assets/img/services-details/reusable_artifacts.png" alt="image">
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->