<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Desktop & Mobile Applications</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Desktop & Mobile Applications</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-110">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Progressive Web Applications</h3>
                <p>At HashTekFusion, we excel in developing cutting-edge desktop and mobile applications using
                    progressive web application (PWA) frameworks. Embracing the "develop once, deploy anywhere" concept,
                    we ensure your applications run seamlessly across Android, iOS, and web platforms.
                </p>
                <h3>Develop Once, Deploy Anywhere</h3>
                <p>Our expertise in modern UI frameworks enables us to create responsive web applications that provide a
                    consistent user experience across all devices. By leveraging frameworks such as Angular, React, Vue,
                    and Flutter, we develop robust applications that meet your business needs and user
                    expectations. </p>
                <h3>Accelerate Development with Reusable Component</h3>
                <p>To streamline the development process, our experts have created a comprehensive sandbox with reusable
                    UI components. This approach allows us to quickly build and deploy UI pages, ensuring faster
                    turnaround times and higher quality results. Each component is rigorously tested to guarantee
                    reliability and performance.</p>
                <h3>Key Features of Our Solutions</h3>
                <ul class="ul-content">
                    <li><b>Progressive Web Applications: </b> Harness the power of PWAs to deliver a native app-like
                        experience on the web.
                    </li>
                    <li><b>Cross-Platform Compatibility:</b> Develop applications that work seamlessly on Android, iOS,
                        and web platforms.
                    </li>
                    <li><b>Responsive Design: </b> Ensure your applications look and function perfectly on any device or
                        screen size using frameworks such as BootStrap.
                    </li>
                    <li><b>Modern UI Frameworks:</b> Utilize Angular, React, Vue, Flutter, and other leading frameworks
                        for superior performance and flexibility.
                    </li>
                    <li><b>Reusable UI Components:</b> Accelerate development with pre-built, well-tested components
                        that ensure consistency and reliability.
                    </li>
                </ul>
                <p>
                    Transform your desktop and mobile application development with HashTekFusion. Let us help you create
                    powerful, cross-platform applications that drive engagement and deliver results.
                </p>
            </div>

            <div class="services-details-image wow fadeInUp">
                <img src="assets/img/services-details/webapp.png" alt="webapp">
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->