<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Content Management and Portal Solutions</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Content Management and Portal Solutions</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-110">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Expertise in Leading Content Management Solutions</h3>
                <p>At HashTekFusion, we specialize in delivering comprehensive Content Management and Portal Solutions
                    tailored to your business needs. Our experts are proficient in leading content management platforms
                    such as TeamSite/LiveSite, Adobe Experience Manager (AEM), Bloomreach, and more. We provide
                    solutions that enable you to efficiently manage, deploy, and optimize your digital content.
                    Our team is skilled in implementing and customizing various content management systems (CMS) to
                    enhance your digital presence:
                </p>
                <ul class="ul-content">
                    <li><b>TeamSite/LiveSite:</b> Streamline your content creation and delivery processes with robust
                        features for managing complex websites.
                        Define and edit taxonomies to categorize your content logically and consistently. This helps in
                        improving content discoverability and enhancing the user experience.
                        Apply metadata to your content to facilitate easy searching, sorting, and filtering. Metadata
                        helps in providing context and improving the relevance of content delivered to your audience.
                        Utilize taxonomy to deliver personalized content to users based on their preferences and
                        behavior. This ensures that your audience receives relevant and engaging content.
                        mprove your site's search capabilities by integrating taxonomy with search functionality. This
                        ensures that users can find the content they need quickly and easily.
                    </li>
                    <li><b>Adobe Experience Manager (AEM): </b> Leverage AEM's powerful workflow features to deploy and
                        manage your content with ease. From content creation to publishing, AEM offers an intuitive and
                        flexible environment that adapts to your organizational needs.
                        Efficiently manage and organize your digital assets with AEM’s Digital Asset Management (DAM)
                        capabilities, enhancing collaboration and reuse.
                        Deliver personalized content across various channels, including web, mobile, and social,
                        ensuring a consistent user experience.
                    </li>
                    <li><b>Bloomreach:</b> Optimize your digital experiences with Bloomreach's AI-driven solutions,
                        ensuring personalized and engaging content for your audience.
                    </li>
                </ul>

                <p>Transform your content management and portal experiences with HashTekFusion. Let us help you create,
                    manage, and deliver compelling digital content that drives engagement and achieves your business
                    goals.</p>
            </div>

            <div class="services-details-image wow fadeInUp">
                <img src="assets/img/services-details/portals.png" alt="portals">
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->