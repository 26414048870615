<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="{{location == '/' ? 'crimo-nav' : 'crimo-nav navbar-style-two'}}">
        <div class="{{location == '/' ? 'container-fluid' : 'container'}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/hashtek-logo1.png" alt="logo" height="210">
                </a>

                <!--button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button-->

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"
                               class="nav-link">Home</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/services" routerLinkActive="active" class="nav-link">Services</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/about" routerLinkActive="active" class="nav-link">About Us</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li>
                    </ul>

                    <!--div class="others-options">
                        <div class="option-item">
                            <i class="search-btn flaticon-search"></i>
                            <i class="close-btn fas fa-times"></i>

                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
                                        <button class="search-button" type="submit">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <a routerLink="contact" class="btn btn-primary">Free Quote</a>
                    </div-->
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->