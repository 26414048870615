<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/hashtek-logo1.png" alt="image"></a>
                        <p>Lead with innovation and deliver with precision!</p>
                    </div>

                    <ul class="social">
                        <li><a href="https://www.linkedin.com/company/hashtekfusion/" target="_blank"><i
                                class="flaticon-linkedin-letters"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Services</h3>

                    <ul class="footer-services-list">
                        <li><a routerLink="/architecture-details">Enterprise & Solution Architecture</a></li>
                        <li><a routerLink="/integration-details">Enterprise Integration Services</a></li>
                        <li><a routerLink="/cloud-details">Cloud Solutions</a></li>
                        <li><a routerLink="/webapp-details">Desktop & Mobile Applications</a></li>
                        <li><a routerLink="/portal-details">Content Management & Portal Solutions</a></li>
                        <li><a routerLink="/analytics-details">Data Analytics & Reporting</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="quick-links-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="/services">Services</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-contact-list">
                        <li><span>Address: </span> 25 Coho Drive, Whitby, ON L1P 0K4, Canada</li>
                        <li><span>Email: </span> <a
                                href="mailto:rajesh@hashtekfusion.com">sales&#64;hashtekfusion.com</a>
                        </li>
                        <li><span>Phone: </span> <a href="tel:+16472963870">+1 647-296-3870</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>&copy; Copyright {{ currentYear }} <a routerLink="/home">HashTekFusion IT Consulting Inc</a>. All
                        Rights Reserved.</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-map"><img src="assets/img/circle-map.png" alt="image"></div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i></div>
