<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>We've Been Thriving in 20+ Years The Tech Area</h2>
                    <p> With over 22 years of experience in the IT sector, our experts at HashTekFusion bring a wealth
                        of knowledge and expertise. Specializing in Java/J2EE technologies, they have excelled as
                        Solution Architects and Consultants for more than 9 years, particularly in Telecom and
                        e-Commerce domains. Their proficiency extends to Retail Banking & Financial sectors and Cloud
                        Services, showcasing mastery in Java, JSP, Servlets, Struts framework, and the Spring framework,
                        alongside a deep understanding of various web technologies.

                        Our team is well-versed in leading technologies such as Weblogic Portal, WebSphere Portal
                        Server, ATG/Oracle Commerce, and other cutting-edge portal technologies. They are skilled in
                        Agile and SAFe project methodologies, consistently delivering projects with precision.
                        Furthermore, they have successfully transformed monolithic applications into Microservices-based
                        architectures, contributing to significant business advancements.</p>
                    <a routerLink="/about" class="btn btn-primary">View More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start FunFacts Area -->
<section class="funfacts-area ptb-110 bg-f2feee">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Facts</span>
                <h2>20+ years of industry experience with Fortune 500 clients</h2>
                <p>HashTek Professionals accomplished projects successfully for various Fortune 500 clients in the past
                    20+ years.
                    Our happy clients includes AT&T, Hewlett Packard, NYSE, GE, Citibank and so on </p>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="80"></div>
                    <span>Entperise Consulting</span>
                    <h3>Solutions</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="90"></div>
                    <span>Our happy</span>
                    <h3>Clients</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="85"></div>
                    <span>Projects</span>
                    <h3>Design</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-3 col-sm-4 offset-lg-0 offset-md-0 offset-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="90"></div>
                    <span>Completed</span>
                    <h3>Projects</h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FunFacts Area -->